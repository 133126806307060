import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 360.000000 336.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,336.000000) scale(0.100000,-0.100000)">
					<path d="M0 1680 l0 -1680 1800 0 1800 0 0 1680 0 1680 -1800 0 -1800 0 0
-1680z m2897 1570 c41 -19 41 -29 3 -43 -18 -7 -36 -25 -46 -45 -17 -36 -20
-36 -38 0 -6 13 -26 32 -43 43 -18 11 -32 22 -33 25 0 3 13 11 28 18 16 7 36
28 46 48 l17 37 17 -34 c9 -19 31 -41 49 -49z m336 -124 c-10 -11 -18 -22 -19
-24 -1 -2 -14 8 -28 23 -20 20 -23 27 -11 31 8 3 17 15 20 25 6 19 8 19 31 -7
24 -25 25 -27 7 -48z m107 -328 c17 -15 50 -33 75 -39 59 -15 64 -25 18 -34
-83 -15 -113 -45 -136 -135 l-14 -55 -19 59 c-24 75 -59 110 -125 126 -56 14
-55 19 12 44 64 23 87 48 106 116 l16 60 19 -58 c11 -33 31 -68 48 -84z m-869
47 c96 -15 307 -69 319 -82 3 -3 -17 -3 -45 1 -189 23 -255 27 -375 22 -285
-12 -528 -75 -797 -208 -187 -91 -308 -172 -477 -318 -64 -55 -203 -220 -262
-311 -72 -111 -133 -246 -165 -369 -32 -123 -33 -323 -1 -433 26 -91 89 -220
142 -291 142 -192 452 -372 768 -446 28 -6 52 -16 52 -21 0 -12 -316 -11 -382
1 -29 6 -78 15 -108 21 -413 80 -719 336 -816 684 -25 91 -30 283 -10 401 58
338 305 691 664 948 235 168 485 284 772 357 218 55 518 74 721 44z m244 -170
c338 -49 581 -207 696 -450 26 -56 64 -183 57 -191 -2 -1 -23 30 -47 71 -135
229 -412 388 -741 426 -129 14 -369 7 -500 -16 -177 -32 -421 -121 -595 -218
-169 -95 -280 -179 -414 -316 -103 -106 -103 -89 0 50 137 185 351 359 581
472 321 158 653 217 963 172z m-1129 -894 c7 -1 15 -9 19 -18 14 -36 127 -359
136 -390 6 -18 13 -33 17 -33 4 0 38 97 77 215 38 119 75 221 82 227 21 17
461 6 493 -12 95 -56 109 -183 26 -243 -16 -12 -14 -15 20 -37 63 -42 79 -72
79 -148 0 -81 -25 -124 -89 -153 -36 -16 -68 -19 -263 -19 l-223 0 -1 138 c-1
157 -12 269 -23 236 -4 -11 -33 -99 -65 -195 l-57 -174 -55 -3 -55 -3 -69 196
c-38 107 -72 195 -75 195 -4 0 -5 -88 -2 -195 l4 -195 -61 0 -61 0 0 304 c0
344 -8 316 85 311 28 -2 55 -4 61 -4z"/>
					<path d="M2180 1615 l0 -77 81 4 c90 4 109 16 109 73 0 57 -19 69 -109 73
l-81 4 0 -77z"/>
					<path d="M2187 1443 c-4 -3 -7 -44 -7 -90 l0 -83 93 0 c112 0 137 15 137 81 0
53 -13 74 -51 88 -35 12 -161 15 -172 4z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
